import { useEffect, useState } from "react";
import { Provider } from "react-redux";
import "./App.less";
import AppRouter from "./router/AppRouter";
import store from "./store";
import BounceLoader from "react-spinners/BounceLoader";
import { css } from "@emotion/react";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

function App() {
  let [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);
  return (
    <Provider store={store}>
      {loading ? (
        <div className="container">
          <div className="child">
            <BounceLoader color={"#fc4000"} loading={loading} css={override} size={100} />
          </div>
        </div>
      ) : (
        <AppRouter />
      )}
    </Provider>
  );
}

export default App;
