import { Menu } from "antd";
import { UserOutlined, DollarCircleOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { ROOT_URL } from "../../constant/route-path";
import { logOut } from "../../service/keycloak";
import { useAppSelector } from "../../store/hooks";
import { IAuthSlice } from "../../model/IAuthSlice";

const { SubMenu } = Menu;
const NavigationBar = () => {
  const { username } = useAppSelector((state: { authSlice: IAuthSlice }) => state.authSlice);
  const navigate = useNavigate();
  const handleClick = (e: any) => {
    switch (e.key) {
      case "pricing-config":
        navigate(ROOT_URL);
        break;
      case "logout":
        logOut();
        break;
      default:
        break;
    }
  };
  return (
    <div style={{ display: "flex" }}>
      <img className="logo" src={window.location.origin + "/image/logo.png"} alt="" width={"150px"} height={"55px"} />
      <Menu onClick={handleClick} defaultSelectedKeys={["pricing-config"]} mode="horizontal" className="main-menu">
        <Menu.Item key="pricing-config" icon={<DollarCircleOutlined />}>
          PRICING CONFIG
        </Menu.Item>
        <SubMenu key="user" icon={<UserOutlined />} title={username}>
          <Menu.Item key="logout">LOGOUT</Menu.Item>
        </SubMenu>
      </Menu>
    </div>
  );
};

export default NavigationBar;
