import { Affix, Alert, Divider, Layout } from "antd";
import AccessDenied from "../component/common/AccessDenied";
import NavigationBar from "../component/common/NavigationBar";
import config from "../config/env";
import loadable from "@loadable/component";
import PageBreadcrumb from "../component/common/PageBreadcrumb";
import { BREADCRUMBMAP } from "../constant/breadcrumb";
import setKeycloak, {
  hasAladdinClientReadOnlyRole,
  hasValidRole,
  initKeyCloak,
} from "../service/keycloak";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { setAuthInfors } from "../store/slice/auth-slice";
import { useEffect } from "react";

const { Header, Content } = Layout;
const ComponentWrapper = (props: { page: string; }) => {
  const { page, ...rest } = props;
  const dispatch = useAppDispatch();
  const { authenticated, realmAccessRoles } = useAppSelector((state: { authSlice: any }) => state.authSlice);
  const Component = loadable(() => import("../page/" + page));

  useEffect(() => {
    let isUnmounted = false;
    if (!authenticated) {
      initKeyCloak().then((kc) => {
        if (!isUnmounted) {
          const { realmAccess, idTokenParsed } = kc;
          setKeycloak(kc);
          dispatch(setAuthInfors({ authenticated: true, realmAccessRoles: realmAccess?.roles, idTokenParsed }));
        }
      });
    }
    return () => {
      isUnmounted = true;
    };
  }, [dispatch]);

  if (!authenticated) {
    return null;
  }
  return (
    <Layout className="layout-admin">
      <Header className={`header-admin ${config.ENVIRONMENT}`}>
        <NavigationBar />
      </Header>
      <Content>
        <Affix offsetTop={10}>
          {hasAladdinClientReadOnlyRole(realmAccessRoles) && (
            <Alert showIcon type="warning" message="Read Only Access" />
          )}
          <Divider orientation="left">
            <PageBreadcrumb crumb={BREADCRUMBMAP[page as keyof typeof BREADCRUMBMAP]} />
          </Divider>
        </Affix>
        {hasValidRole(realmAccessRoles) ? (
          <div className="site-layout-content">
            <Component {...rest} />
          </div>
        ) : (
          <AccessDenied />
        )}
      </Content>
    </Layout>
  );
};

export default ComponentWrapper;
