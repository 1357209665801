let ALADDIN_PRICING_BASE;
let KEYCLOAK_AUTH_BASE;
let KEYCLOAK_CONFIG;
let ENVIRONMENT = 'dev';
if (window.location.href.indexOf('localhost:3000') !== -1) {
    ALADDIN_PRICING_BASE = 'https://aladdin-api-gateway-dev.m-daq.com/pricing';
    KEYCLOAK_AUTH_BASE = 'https://auth-nonprod-dev.m-daq.com/auth';
    KEYCLOAK_CONFIG = '/keycloak.json';
} else if (window.location.href.indexOf('aladdin-client-admin-dev') !== -1) {
    ALADDIN_PRICING_BASE = 'https://aladdin-api-gateway-dev.m-daq.com/pricing';
    KEYCLOAK_AUTH_BASE = 'https://auth-nonprod-dev.m-daq.com/auth';
    KEYCLOAK_CONFIG = '/keycloak.json';
} else if (window.location.href.indexOf('aladdin-client-admin-uat') !== -1) {
    ALADDIN_PRICING_BASE = 'https://aladdin-api-gateway-uat.m-daq.com/pricing';
    KEYCLOAK_AUTH_BASE = 'https://auth-nonprod.m-daq.com/auth';
    KEYCLOAK_CONFIG = '/keycloak_uat.json';
} else if (window.location.href.indexOf('aladdin-client-admin-fuat') !== -1) {
    ALADDIN_PRICING_BASE = 'https://aladdin-api-gateway-fuat.m-daq.com/pricing';
    KEYCLOAK_AUTH_BASE = 'https://auth-nonprod.m-daq.com/auth';
    KEYCLOAK_CONFIG = '/keycloak_fuat.json';
} else if (window.location.href.indexOf('aladdin-client-admin') !== -1) {
    ALADDIN_PRICING_BASE = 'https://aladdin-api-gateway.m-daq.com/pricing';
    KEYCLOAK_AUTH_BASE = 'https://auth.m-daq.com/auth';
    KEYCLOAK_CONFIG = '/keycloak_prod.json';
    ENVIRONMENT = 'prod';
}

export default {
    ALADDIN_PRICING_BASE,
    KEYCLOAK_AUTH_BASE,
    KEYCLOAK_CONFIG,
    ENVIRONMENT,
};
