import { Result } from 'antd';

export const AccessDenied = () => (
    <Result
        status="warning"
        title="You don't have permission to access this site."
        extra={<p>Please contact our technicians for support.</p>}
    />
);

export default AccessDenied;
