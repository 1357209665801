import React from "react";
import { Breadcrumb } from "antd";
import { useNavigate, useParams } from "react-router-dom";

const PageBreadcrumb = (props: { crumb: any }) => {
  const { crumb } = props;
  const navigate = useNavigate();
  var { id } = useParams();
  const onClick = (path: any) => {
    navigate(path.replace(":id", id));
  };
  const navList = crumb.map(
    (item: {
      key: React.Key | null | undefined;
      color: any;
      path: any;
      value: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined;
    }) => (
      <Breadcrumb.Item key={item.key}>
        <span style={{ color: item.color }} onClick={() => onClick(item.path)}>
          {item.value}
        </span>
      </Breadcrumb.Item>
    )
  );

  return <Breadcrumb separator=">">{navList}</Breadcrumb>;
};

export default PageBreadcrumb;
