import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import * as page from "../constant/page";
import { EDIT_PRICING_CONFIG_URL, PRICING_CONFIG_URL, ROOT_URL } from "../constant/route-path";
import ComponentWrapper from "./ComponentWrapper";

const AppRouter = () => (
  <Router>
    <Routes>
      <Route path={ROOT_URL} element={<Navigate to={PRICING_CONFIG_URL} />} />
      <Route path={PRICING_CONFIG_URL} element={<ComponentWrapper page={page.PRICING_CONFIG} />} />
      <Route path={EDIT_PRICING_CONFIG_URL} element={<ComponentWrapper page={page.PRICING_CONFIG_EDIT} />} />
    </Routes>
  </Router>
);
export default AppRouter;
