import { createSlice } from "@reduxjs/toolkit";
import { IPricingSlice } from "../../model/IPricingSlice";

export const initialState: IPricingSlice = {
  currentMarkupConfig: undefined,
  currentMarkupConfigs: [],
  pricingConfig: undefined,
  summaryDataSource: [],
};
export const pricingSlice = createSlice({
  name: "pricingSlice",
  initialState,
  reducers: {
    resetState: () => initialState,
    setPricingConfig: (state, action) => {
      state.pricingConfig = action.payload;
      state.currentMarkupConfigs = action.payload.markupConfigsExternal;
    },
    setMarkupConfig: (state, action) => {
      state.currentMarkupConfig = action.payload;
    },
    updateMarkupConfig: (state, action) => {
      state.currentMarkupConfig = action.payload.markupConfig;
      state.currentMarkupConfigs = action.payload.markupConfigs;
      state.summaryDataSource = action.payload.summaryDataSource;
    },
  },
  extraReducers: (builder) => {},
});

export const { resetState, setPricingConfig, setMarkupConfig, updateMarkupConfig } = pricingSlice.actions;
export default pricingSlice.reducer;
