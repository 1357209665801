import Keycloak from "keycloak-js";
import config from "../config/env";
import role from "../constant/keycloak-role";

export let kc_instance: Keycloak.KeycloakInstance;

export const validUserRole = (roles: string | any[]) =>
  roles.includes(role.Aladdin_Client_Admin_Access) || roles.includes(role.Aladdin_Client_Readonly_Access);

export const initKeyCloak = (): Promise<Keycloak.KeycloakInstance> => {
  const keycloak = Keycloak(config.KEYCLOAK_CONFIG);
  return new Promise((resolve, reject) => {
    keycloak  
      .init({
        onLoad: "login-required",
        checkLoginIframe: true,
      })
      .then(() => {
        return resolve(keycloak)})
      .catch(() => reject());
  });
}

export const setAuthorizationToken = async (request: { headers: { Authorization: string } }) => {
  return new Promise((resolve) => {
    kc_instance
      .updateToken(5)
      .then(() => {
        request.headers.Authorization = `Bearer ${kc_instance.token}`;
        resolve(kc_instance);
      })
      .catch(() => {
        kc_instance.logout();
      });
  });
};

export const logOut = () => {
  kc_instance.logout();
};

export const hasAladdinClientReadOnlyRole = (roles: string[]) => roles.includes(role.Aladdin_Client_Readonly_Access);

export const hasValidRole = (roles: string[]) => roles.includes(role.Aladdin_Client_Admin_Access) ||  roles.includes(role.Aladdin_Client_Readonly_Access);

const setKeycloak = (instance: Keycloak.KeycloakInstance) => {
  kc_instance = instance;
  return instance;
};

export default setKeycloak;
