import { createSlice } from "@reduxjs/toolkit";
import { IAuthSlice } from "../../model/IAuthSlice";

export const initialState: IAuthSlice = {
  username: "",
  realmAccessRoles: [],
  authenticated: false,
  idTokenParsed: undefined,
};
export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    resetState: () => initialState,
    setAuthInfors: (state, action) => {
      state.username = action.payload.idTokenParsed.preferred_username;
      state.realmAccessRoles = action.payload.realmAccessRoles;
      state.authenticated = action.payload.authenticated;
      state.idTokenParsed = action.payload.idTokenParsed;
    },
  },
  extraReducers: (builder) => {},
});

export const { resetState, setAuthInfors } = authSlice.actions;
export default authSlice.reducer;
