import { ROOT_URL, EDIT_PRICING_CONFIG_URL, PRICING_CONFIG_URL } from "./route-path";
import * as page from "./page";

export const BREADCRUMBMAP = {
  [page.PRICING_CONFIG]: [
    {
      key: 1,
      path: ROOT_URL,
      value: "HOME",
      color: "#000000",
    },
    {
      key: 2,
      path: PRICING_CONFIG_URL,
      value: "PRICING CONFIG",
      color: "#FC4000",
    },
  ],
  [page.PRICING_CONFIG_EDIT]: [
    {
      key: 1,
      path: ROOT_URL,
      value: "HOME",
      color: "#000000",
    },
    {
      key: 2,
      path: PRICING_CONFIG_URL,
      value: "PRICING CONFIG",
      color: "#000000",
    },
    {
      key: 3,
      path: EDIT_PRICING_CONFIG_URL,
      value: "EDIT",
      color: "#FC4000",
    },
  ],
};
